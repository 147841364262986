<template><div>
        <v-expansion-panels popout>
      <v-expansion-panel
        v-for="group in groups"
        :key="group.id"
      >
        <v-expansion-panel-header>{{group.name}}</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-list
      
            two-line
          >

            <v-list-item @click="view(document)"
              v-for="document in group.documents"
              :key="document.id"
            >
              <v-list-item-avatar>
                <v-icon
                    small
                  class="red lighten-1"
                  dark
                >
                  mdi-file-pdf
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="document.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                            <v-btn icon target="_blank" :href="'https://api.learn.mchs.ru/'+document.address">
                  <v-icon color="grey lighten-1">mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>


          </v-list>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
         <v-dialog
      v-model="viewModal"
      width="80%%"
      >
        <object><embed :src="'https://api.learn.mchs.ru/'+fileView.address" width="100%" height="800px" /></object>
        </v-dialog>
    </div>
</template>
<script>

    export default {
        name: "index",
        data: () => ({
            groups:[],
            loading:true,
            viewModal:false,
            fileView:{}
            }),
        methods: {
            view(file){
                this.fileView=file
                this.viewModal=true
            },
            fetchData() {
                this.$http.get('documents/get-all').then(response => {
                    this.groups = response.data
                    this.loading=false
                })

            }
        },
        created() { this.fetchData() }
    };
</script>